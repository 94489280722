import { createApp } from 'vue';
import ViewUI from 'view-ui-plus';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import VueLazyLoad from 'vue-lazyload';
import 'view-ui-plus/dist/styles/viewuiplus.css';
import injectRequest from '@/utils/statistic';
import WujieVue from 'wujie-vue3';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { setupWujieApp } from '@/utils/child-app';
import { initMonitor } from '@/utils/monitor';
import { onlyConsoleError } from '@/utils/util';

// 在Vue初始化之前监听，避免遗漏
initMonitor();

const app = createApp(App);
app.use(store).use(router).use(ViewUI)
  .component('vue-json-pretty', VueJsonPretty)
  .use(WujieVue)
  .use(VueLazyLoad, {
    // eslint-disable-next-line import/no-unresolved,global-require
    loading: require('../public/images/default_user.png'), // 图片未加载时显示的默认图片
    preload: 1, // 提前加载的高度，默认1屏，含义是只要被v-lazy绑定的图片在1屏的可视范围内，就会执行加载，否则只显示默认图片、而不执行加载
  });
const instance = app.mount('#layout');
instance.$Message.config({
  duration: 3, // 全局提示持续时间为3s
});
setupWujieApp();
injectRequest();
onlyConsoleError('production');

export default instance;
