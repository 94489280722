import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-60fc6e1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "layout-logo" }
const _hoisted_2 = { class: "layout-logo-text" }
const _hoisted_3 = { class: "layout-nav" }
const _hoisted_4 = { id: "photo" }
const _hoisted_5 = {
  style: {"vertical-align":"middle"},
  alt: ""
}
const _hoisted_6 = { href: "javascript:void(0)" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_DropdownItem = _resolveComponent("DropdownItem")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_Header = _resolveComponent("Header")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createBlock(_component_Header, null, {
    default: _withCtx(() => [
      _createVNode(_component_Menu, {
        mode: "horizontal",
        theme: "dark",
        "active-name": "1"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_Icon, {
              type: _ctx.$store.state.ico,
              color: "white",
              size: "52"
            }, null, 8, ["type"])
          ]),
          _createElementVNode("div", _hoisted_2, _toDisplayString(this.$store.state.title), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", _hoisted_4, [
              _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [
                [_directive_lazy, _ctx.$store.state.userPhoto]
              ])
            ]),
            _withDirectives(_createVNode(_component_Dropdown, { onOnClick: _ctx.clickMenu }, {
              list: _withCtx(() => [
                (!!_ctx.$store.state.userId)
                  ? (_openBlock(), _createBlock(_component_DropdownMenu, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DropdownItem, { name: "index" }, {
                          default: _withCtx(() => [
                            _createTextVNode("主页")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_DropdownItem, { name: "design" }, {
                          default: _withCtx(() => [
                            _createTextVNode("设计")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_DropdownItem, { name: "exit" }, {
                          default: _withCtx(() => [
                            _createTextVNode("退出")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_DropdownMenu, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DropdownItem, { name: "index" }, {
                          default: _withCtx(() => [
                            _createTextVNode("主页")
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_DropdownItem, { name: "exit" }, {
                          default: _withCtx(() => [
                            _createTextVNode("退出")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
              ]),
              default: _withCtx(() => [
                _createElementVNode("a", _hoisted_6, [
                  _createTextVNode(_toDisplayString(_ctx.$store.state.userName) + " ", 1),
                  _createVNode(_component_Icon, { type: "ios-arrow-down" })
                ])
              ]),
              _: 1
            }, 8, ["onOnClick"]), [
              [_vShow, !!_ctx.$store.state.userName]
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}