import request from '@/utils/request';

export function login({
  params,
}: {
  params: {
    username: string,
    password: string,
    expireDays: number;
  }
}) {
  return request({
    url: '/login',
    method: 'get',
    params,
  });
}

export function logout() {
  return request({
    url: '/logout',
    method: 'get',
  });
}

export function getUserInfo() {
  return request({
    url: '/api/getUserInfo',
    method: 'get',
  });
}

export function modifyDesignForm(data: {
  designFormConfig: string
}) {
  return request({
    url: '/api/modifyDesignForm',
    method: 'post',
    data,
  });
}
