import WujieVue from 'wujie-vue3';

const { setupApp, preloadApp } = WujieVue;

export function setupWujieApp() {
  // 设置子应用属性
  setupApp({
    name: 'design', // 子应用名称
    url: 'https://design.lijiaheng.cn', // 子应用url
    fiber: true, // 按JS文件粒度加载子应用JS
    alive: true, // 保活
    plugins: [{
      // 在子应用所有的js之前执行
      jsBeforeLoaders: [
        {
          callback(appWindow: Window | any) {
            // __WUJIE是wujie注入window的全局变量，其他变量参考：https://wujie-micro.github.io/doc/guide/variable.html
            console.log(`${new Date()} 子应用${appWindow.__WUJIE!.id}开始渲染`);
          },
        },
      ],
      // 在子应用所有的js之后执行
      jsAfterLoaders: [
        {
          callback(appWindow: Window | any) {
            console.log(`${new Date()} 子应用${appWindow.__WUJIE!.id}渲染结束`);
          },
        },
      ],
    }],
  });
  setupApp({
    name: 'design', // 子应用名称
    url: 'https://design.lijiaheng.cn/show', // 子应用url
    fiber: true, // 按JS文件粒度加载子应用JS
    alive: false, // 不需要保活，避免标记的表单状态保留下来、影响标记其他实验，此外如果保活，props变化时也无法重新渲染
  });
}

// 预加载子应用
export function preloadWujieApp() {
  // 预加载子应用
  preloadApp({
    name: 'design', // 子应用名称
    url: 'https://design.lijiaheng.cn', // 子应用url
    // 预加载默认只从网络加载静态资源，而不执行渲染，开启exec则也会预执行，这里考虑到子应用需要动态传入props执行，
    // 否则预执行是没有用的，因此不开启exec
    // exec: true,
  });
  preloadApp({
    name: 'design',
    url: 'https://design.lijiaheng.cn/show',
  });
}
