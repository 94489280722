import { proxy } from 'ajax-hook';

const injectRequest = () => {
  proxy({
    // 请求前
    onRequest: (config, handler) => {
      console.log('请求前', config);
      // @ts-ignore
      handler.xhr.startTime = new Date();
      handler.next(config);
    },
    // 请求错误时（如超时；但不包括http状态码错误，如404仍然会认为请求成功）
    onError: (err, handler) => {
      // @ts-ignore
      console.log(`请求错误（${new Date() - handler.xhr.startTime}ms）`, err);
      handler.next(err);
    },
    // 请求成功时
    onResponse: (response, handler) => {
      // @ts-ignore
      console.log(`成功响应（${new Date() - handler.xhr.startTime}ms）`, response);
      handler.next(response);
    },
  });
};

export default injectRequest;
