
import LayoutHeader from '@/components/layout-header.vue';
import LayoutFooter from '@/components/layout-footer.vue';

import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    LayoutHeader,
    LayoutFooter,
  },
});
