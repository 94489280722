/* eslint-disable */
import { MonitorJS } from 'monitorjs_horse';

// 错误监控，当出现未捕获的Exception时，会上报一条如下消息给url：
// {
//   "project": "trial-web",
//   "time": "2023/6/17 17:46:32",
//   "category": "promise_error",
//   "logType": "Warning",
//   "logInfo": "错误类别: promise_error\r\n日志信息: [object Object]\r\nurl: \r\n其他错误: \"\"\r\n设备信息: {\"deviceType\":\"PC\",\"OS\":\"Mac OS\",\"OSVersion\":\"10.15.7\",\"screenHeight\":960,\"screenWidth\":1536,\"language\":\"zh_CN\",\"netWork\":\"4g\",\"orientation\":\"横屏\",\"browserInfo\":\"Chrome（版本: 114.0.0.0&nbsp;&nbsp;内核: Blink）\",\"fingerprint\":\"680e9cef\",\"userAgent\":\"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36\"}",
//   "deviceInfo": "{\"deviceType\":\"PC\",\"OS\":\"Mac OS\",\"OSVersion\":\"10.15.7\",\"screenHeight\":960,\"screenWidth\":1536,\"language\":\"zh_CN\",\"netWork\":\"4g\",\"orientation\":\"横屏\",\"browserInfo\":\"Chrome（版本: 114.0.0.0&nbsp;&nbsp;内核: Blink）\",\"fingerprint\":\"680e9cef\",\"userAgent\":\"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36\"}"
// }
const initErrorMonitor = () => {
  new MonitorJS().init({
    url: 'https://www.lijiaheng.cn/report/error', // 上报地址
    consoleError: true, // 是否记录console.error
    vueError: true, // 是否记录vue错误信息
    extendsInfo: { // 自定义扩展信息，一般用于区分不同项目
      project: 'trial-web', // 静态扩展信息
      getDynamic: () => ({ // 动态扩展信息
        time: new Date().toLocaleString(),
      }),
    },
  });
};

// 性能监控，页面初始化完成后，会上报如下数据给url：
// {
//   "project": "trial-web",
//   "time": "2023/6/17 19:14:37",
//   "category": "network_speed",
//   "logType": "Info",
//   "logInfo": "{\"curTime\":\"2023-06-17 19:14:37\",\"pageId\":\"index\",\"networkSpeed\":\"792.38\",\"deviceInfo\":\"{\\\"deviceType\\\":\\\"PC\\\",\\\"OS\\\":\\\"Mac OS\\\",\\\"OSVersion\\\":\\\"10.15.7\\\",\\\"screenHeight\\\":960,\\\"screenWidth\\\":1536,\\\"language\\\":\\\"zh_CN\\\",\\\"netWork\\\":\\\"4g\\\",\\\"orientation\\\":\\\"横屏\\\",\\\"browserInfo\\\":\\\"Chrome（版本: 114.0.0.0&nbsp;&nbsp;内核: Blink）\\\",\\\"fingerprint\\\":\\\"680e9cef\\\",\\\"userAgent\\\":\\\"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36\\\"}\"}"
// }
const initPerformanceMonitor = () => {
  new MonitorJS().monitorPerformance({
    pageId: 'index', // 页面唯一标识
    url: 'https://www.lijiaheng.cn/report/performance', // 上报地址
    isPage: true, // 是否上报页面性能数据
    isResource: true, // 是否上报页面资源数据
    isRNetworkSpeed: true, // 是否需要上报网速
    extendsInfo: { // 自定义扩展信息，一般用于区分不同项目
      project: 'trial-web', // 静态扩展信息
      getDynamic: () => ({ // 动态扩展信息
        time: new Date().toLocaleString(),
      }),
    },
  });
};

export const initMonitor = () => {
  initErrorMonitor();
  initPerformanceMonitor();
};
