import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import ViewUIPlus from 'view-ui-plus';
import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    // 这里使用webpack的注释给分割后的组件起名，增加辨识度（否则名称则为随机串），
    // 比如下面的组件打包后的js名称则为tables.5d66eafe.js
    component: () => import(/* webpackChunkName: "tables" */ '@/views/tables.vue'),
  },
  {
    path: '/tables',
    component: () => import(/* webpackChunkName: "tables" */ '@/views/tables.vue'),
  },
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login.vue'),
  },
  {
    path: '/add',
    component: () => import(/* webpackChunkName: "add" */ '@/views/add.vue'),
  },
  {
    path: '/modify',
    component: () => import(/* webpackChunkName: "modify" */ '@/views/modify.vue'),
  },
  {
    path: '/trash',
    component: () => import(/* webpackChunkName: "trash" */ '@/views/trash.vue'),
  },
  {
    path: '/design',
    component: () => import(/* webpackChunkName: "design" */ '@/views/design.vue'),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// 设置顶部进度条
router.beforeEach((to, from, next) => {
  ViewUIPlus.LoadingBar.start();
  next();
});

router.afterEach((to) => {
  // 路由切换时修改标题和图标
  store.commit('setTitle', store.state.url2title[to.path]);
  store.commit('setIco', store.state.url2ico[to.path]);
  ViewUIPlus.LoadingBar.finish();
});

export default router;
