import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import router from '@/router';
import store from '@/store';
import main from '@/main';
import _ from 'lodash';

const request = axios.create({
  timeout: 10000, // 请求超时时间
});

// 跨域的请求如 a.com 的脚本访问 b.com，默认不会携带 b.com 域名下的cookie（withCredentials默认false，需要配置为true）
request.defaults.withCredentials = true;

// axios 请求拦截器
request.interceptors.request.use((config: InternalAxiosRequestConfig) => config);

// axios 响应拦截器
request.interceptors.response.use(
  (response: AxiosResponse<{
    code: number;
  }>) => {
    if (response.data.code !== 200 && _.has(response.headers, 'traceid')) {
      console.error({
        status: response.status,
        method: response.config.method,
        url: response.config.url,
        traceId: response.headers.traceid,
      });
    }
    // 参数错误
    if (response.data.code === 400) {
      main.$Message.error('请求错误~请检查参数!');
    }
    // 未登录，并跳转到登录页
    if (response.data.code === 401) {
      // 同一个页面多次触发ajax请求的情况下，避免重复冒泡提示
      if (store.state.userName !== '') {
        main.$Message.error('请先登录');
        store.commit('setUserName', '');
        store.commit('setIsShowUserPhoto', false);
      }
      router.push('/login');
    }
    // 登录失败
    if (response.data.code === 402) {
      main.$Message.error('登录失败');
    }
    // 服务器内部错误
    if (response.data.code === 500) {
      main.$Message.error('服务器异常');
      router.push('/login');
    }
    return response;
  },
  // 状态码不是2xx则进入这里
  (err: any) => {
    main.$Message.error('请求失败');
    console.error('请求失败：', err);
    router.push('/login');
    return Promise.reject(err);
  },
);

export default request;
