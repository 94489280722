import { createStore } from 'vuex';

export default createStore({
  state: {
    // 登录有效期，单位是天。若为0，则按照服务端的默认过期时间设置（目前服务端默认值为1天）
    expireDays: 0,
    userId: '',
    userName: '',
    userPhoto: '',
    userWelcomeText: '',
    userDesignForm: {},
    isShowUserPhoto: false,
    title: 'wefl 的小屋',
    url2title: {
      '/': 'wefl 的小屋',
      '/tables': '信息查看',
      '/add': '添加实验记录',
      '/modify': '修改实验记录',
      '/trash': '回收站',
      '/login': '登录',
      '/design': '设计标记表单',
    } as {[key: string ]: string},
    ico: 'ios-podium-outline',
    url2ico: {
      '/': 'ios-podium-outline',
      '/tables': 'ios-podium-outline',
      '/add': 'ios-add-circle-outline',
      '/modify': 'ios-create-outline',
      '/trash': 'ios-trash-outline',
      '/login': 'ios-contact-outline',
      '/design': 'ios-bulb-outline',
    } as {[key: string ]: string},
    trialTypeDict: {
      1: '样式实验',
      2: '功能实验',
      3: '算法实验',
    },
    pageTypeDict: {
      1: '站外列表页',
      2: '站外搜索页',
      3: '站外抖音页',
      4: '站外快手页',
      5: '站外网盟页',
      6: '站内匠心京选',
      7: '站内萤火虫页',
      8: '站内自动化页',
    },
    trialSignList: [
      {
        value: '负',
        label: '负',
      },
      {
        value: '正',
        label: '正',
      },
    ],
    editor: {},
  },
  // 使用mutations而不是直接修改state, 主要是为了调试时可以追踪
  mutations: {
    setExpireDays(state, expireDays) {
      state.expireDays = expireDays;
    },
    setTitle(state, title) {
      state.title = title;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserName(state, userName) {
      state.userName = userName;
    },
    setUserPhoto(state, userPhoto) {
      state.userPhoto = userPhoto;
    },
    setUserWelcomeText(state, userWelcomeText) {
      state.userWelcomeText = userWelcomeText;
    },
    setUserDesignForm(state, userDesignForm) {
      if (!userDesignForm) {
        state.userDesignForm = {};
      } else {
        try {
          state.userDesignForm = JSON.parse(userDesignForm);
        } catch (err) {
          state.userDesignForm = userDesignForm;
        }
      }
    },
    setIsShowUserPhoto(state, isShowUserPhoto) {
      state.isShowUserPhoto = isShowUserPhoto;
    },
    setIco(state, ico) {
      state.ico = ico;
    },
    setEditor(state, editor) {
      state.editor = editor;
    },
  },
});
