import _ from 'lodash';

export function show(o: any, component: string) {
  _.set(o, component, true);
}

export function hide(o: any, component: string) {
  _.set(o, component, false);
}

export function clearCookie() {
  const keys = document.cookie.match(/[^ =;]+(?==)/g);
  if (!keys) {
    return;
  }
  for (const key of keys) {
    // 清除当前域名下的，例如 www.ljiaheng.cn
    document.cookie = `${key}=0;path=/;expires=${new Date(0).toUTCString()}`;
    // 清除当前域名下的，例如 .www.ljiaheng.cn
    document.cookie = `${key}=0;path=/;domain=${document.domain};expires=${new Date(0).toUTCString()}`;
    // 清除一级域名下的或指定的，例如 .ljiaheng.cn
    document.cookie = `${key}=0;path=/;domain=ljiaheng.cn;expires=${new Date(0).toUTCString()}`;
  }
}

// 将页面类型、实验类型的中文转换为对应的数值
export function typeStr2Int(dict: any, str: string) {
  for (const key in dict) {
    if (dict[key] === str) {
      return key;
    }
  }
  return null;
}

// 只允许console.error打印日志
export function onlyConsoleError(env: string) {
  if (process.env.NODE_ENV === env) {
    const doNoThing = (...data: any[]) => null;
    console.log = doNoThing;
    console.debug = doNoThing;
    console.info = doNoThing;
    console.trace = doNoThing;
    console.warn = doNoThing;
  }
}
