
import { logout } from '@/api/user';
import { clearCookie } from '@/utils/util';
import { defineComponent } from 'vue';

export default defineComponent({
  methods: {
    clickMenu(name: string): void {
      if (name === 'exit') {
        logout()
          .then((response: { data: { code: number } }) => {
            if (response.data.code === 200) {
              clearCookie();
              this.$store.commit('setUserName', '');
              this.$store.commit('setUserPhoto', '');
              this.$store.commit('setIsShowUserPhoto', false);
              this.$Message.success('注销成功');
              this.$router.push('/login');
            }
          });
      } else if (name === 'index') {
        this.$router.push('/tables');
      } else if (name === 'design') {
        this.$router.push('/design');
      }
    },
  },
});
