import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layoutHeader = _resolveComponent("layoutHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Card = _resolveComponent("Card")!
  const _component_layoutFooter = _resolveComponent("layoutFooter")!
  const _component_Layout = _resolveComponent("Layout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Layout, { class: "layout" }, {
      default: _withCtx(() => [
        _createVNode(_component_layoutHeader, { class: "layout-header" }),
        _createVNode(_component_Card, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_component_router_view, {
              key: _ctx.$route.fullPath,
              class: "router-view"
            }))
          ]),
          _: 1
        }),
        _createVNode(_component_layoutFooter)
      ]),
      _: 1
    })
  ]))
}